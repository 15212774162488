body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.glowingbackground{
  position: relative;
  width: 600px;
  height: 600px;
  margin: 50px;
  border-radius: 50px;
  max-width: 36%;
  max-height: 99%;
  position: relative;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
}
.glowingbackground::after {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  content: '';
  background: inherit;
  filter: blur(20px);
  transform: scale(1.05);
  opacity: 0.8;
  transition: 500ms all ease-in-out 0ms;
}

.ccard {
  position: relative;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
  background-size: cover !important;
}
.ccard::after {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  content: '';
  background: inherit;
  filter: blur(20px);
  transform: scale(1.05);
  opacity: 0.8;
  transition: 500ms all ease-in-out 0ms;
}

.absolute-thumbnail{
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  z-index: 2;
  border-radius: 1.5em;
  border: 1px solid #fff;
}

.shadow{
  box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 9px 3px;
}
.shadow-0{
  box-shadow:rgba(255, 255, 255, 0.1) 0px 0px 10px 1px;
}

.link-0:hover{
  background-color: rgba(178, 23, 106, 1);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.maincrousel .slider.animated{
  padding: 1em 0 3.5em 0em !important;
  /* padding: 0em 0 1em 0em !important; */
}

.maincrousel .slider.animated .slide{
  border: 1px solid #fff;
  /* border: 1px solid #fff; */
}

.maincrousel .crouselNavigator{
  position:absolute; 
  z-index:1; 
  top:calc(50% - 45px);
  background:transparent;
  border:none;
  border-radius:2em;
  color:"#fff";
  cursor: pointer;
}
