@font-face {
  font-family: 'Agreloy';
  src: url('./fonts/agreloy/Agreloy.ttf') format('truetype');
}

.agreloy{font-family: 'Agreloy' !important;}

html, body, #root, #main{
  width: 100%;
  height: 100%;
}

#chat{
  height: 60vh;
  width: 100%;
}

.text-ellipsis{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}


*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  background-color: #f1d733;
  border-radius: 3px;
}

.social-link{
  display: inline-block;
  padding: 0.2em 0.5em;
}

